import React from 'react'
import './home.scss'

import { Services } from '../../components'

const Home = () => {
  return (
    <div className='home section' id='home'>
      <h1 className="title">
        <span>NÚÑEZ CORUÑA</span>
      </h1>
      <p>
          Contando con <span>más de 90 años de experiencia</span> en el sector 
          de suministros industriales, la <span>calidad</span> en todos los 
          productos que comercializamos está unida a un servicio personalizado 
          con técnicos cualificados que harán la <span>experiencia de compra 
          única e individual.</span> 
      </p>
      <p>
          La amplia gama de artículos que comercializamos se 
          adaptan a gran variedad de sectores y ramas industriales, 
          especializándonos en <span>hidráulica, neumática, transmisión, 
          valvuleria e instrumentación.</span>
      </p>
      <Services />
    </div>
  )
}

export default Home