import React from 'react'
import './contact.scss'

import { AiFillPhone, AiFillMail, AiFillPushpin } from 'react-icons/ai'

const Contact = () => {
  return (
    <div className='contact section'>
      <div className="contactPage">  
        <div className="socialContact">
          <div className="comunicationWrapper">
            <div className="location">  
              <AiFillPushpin className='icon' /> 
              <span>
                Rúa Gutemberg, 32, 15008 <br />
                LA GRELA, A Coruña
              </span>
            </div>
            <div className="phone">
              <AiFillPhone className='icon' />
              <span>981 14 01 94</span>
            </div>
            <div className="mail">
              <AiFillMail className='icon' />
              <a href="mailto:info@nunezcoruna.com">
                info@nunezcoruna.com
              </a>          
            </div>
          </div>
          <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2901.0627801139253!2d-8.42462438508983!3d43.35479737969404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd2e7cedb8c6a6a1%3A0x4e5777cdda3328db!2sN%C3%BA%C3%B1ez%20Coru%C3%B1a%20~%20Productos%20Industriales!5e0!3m2!1ses!2ses!4v1645603688191!5m2!1ses!2ses" 
            width="600" 
            height="450" 
            style={{ border: 0 }} 
            allowFullScreen="" 
            loading="lazy">
          </iframe>
        </div>
        <div className="form">
          <h1 className="section-header">Contacto</h1>
          <form id="contact-form" className="form-horizontal" role="form" action='https://www.actionforms.io/e/r/lucas-nunez-gonzalez-test' method='post'>
            
            <div className="form-group">
              <input type="text" className="form-control" id="name" placeholder="NAME" name="name" required />
            </div>

            <div className="form-group">
              <input type="email" className="form-control" id="email" placeholder="EMAIL" name="email" required />
            </div>

            <textarea className="form-control" rows="10" placeholder="MESSAGE" name="message" required />
            
            <button className="btn btn-primary send-button" id="submit" type="submit" value="SEND">
              <i className="fa fa-paper-plane" />
            </button>

          </form>
        </div>
      </div>
    </div>
  )
}

export default Contact