import React from 'react'
import './providers.scss'

import { Albert, Atos, Baldwin, Brevini, Busch, Camozzi, CdcPneumatics, Cejn, Econosto, ElmoR, Gemu, 
         Genebre, Gesa, Imopac, Kern, Kimo, Kobold, Larzep, Legris, Loctite, Lucifer, Mikalor, NuovaFim, 
         Parker, Pneumax, PowerTeam, PrElectronics, Prevost, Roquet, Salami, Samiflex, Samoa, SauerDanfoss, 
         Serto, Sugiyama, Tecfluid, Texrope, Tollok, Univer, Vivolo, Voss, Walther, Wilkerson 
        } from '../../assets/logos/index';

const Providers = () => {
  return (
    <div className='providers section' id='providers'>
      <div className='titleCard'>
        <span id="titleCard_text">proveedores</span>
      </div>

      <div className="brands_box">
        <div className="brand" id="parker"><img src={ Parker } alt="parker" /></div>
        <div className="brand" id="univer"><img src={ Univer } alt="univer" /></div>
        <div className="brand" id="legris"><img src={ Legris } alt="legris" /></div>
        <div className="brand" id="roquet"><img src={ Roquet } alt="roquet" /></div>
        <div className="brand" id="texrope"><img src={ Texrope } alt="texrope" /></div>
        <div className="brand" id="atos"><img src={ Atos } alt="atos" /></div>
        <div className="brand" id="larzep"><img src={ Larzep } alt="larzep" /></div>
        <div className="brand" id="wilkerson"><img src={ Wilkerson } alt="wilkerson" /></div>
        <div className="brand" id="lucifer"><img src={ Lucifer } alt="lucifer" /></div>
        <div className="brand" id="prevost"><img src={ Prevost } alt="prevost" /></div>
        <div className="brand" id="cejn"><img src={ Cejn } alt="cejn" /></div>
        <div className="brand" id="samoa"><img src={ Samoa } alt="samoa" /></div>
        <div className="brand" id="genebre"><img src={ Genebre } alt="genebre" /></div>
        <div className="brand" id="gemu"><img src={ Gemu } alt="gemu" /></div>
        <div className="brand" id="sauer_danfoss"><img src={ SauerDanfoss } alt="sauer_danfoss" /></div>
        <div className="brand" id="loctite"><img src={ Loctite } alt="loctite" /></div>
        <div className="brand" id="tollok"><img src={ Tollok } alt="tollok" /></div>
        <div className="brand" id="mikalor"><img src={ Mikalor } alt="mikalor" /></div>
        <div className="brand" id="econosto"><img src={ Econosto } alt="econosto" /></div>
        <div className="brand" id="busch"><img src={ Busch } alt="busch" /></div>
        <div className="brand" id="kobold"><img src={ Kobold } alt="kobold" /></div>
        <div className="brand" id="nuova_fima"><img src={ NuovaFim } alt="nuova_fima" /></div>
        <div className="brand" id="gesa"><img src={ Gesa } alt="gesa" /></div>
        <div className="brand" id="tecfluid"><img src={ Tecfluid } alt="tecfluid" /></div>
        <div className="brand" id="kimo"><img src={ Kimo } alt="kimo" /></div>
        <div className="brand" id="imopac"><img src={ Imopac } alt="imopac" /></div>
        <div className="brand" id="sugiyama"><img src={ Sugiyama } alt="sugiyama" /></div>
        <div className="brand" id="samiflex"><img src={ Samiflex } alt="samiflex" /></div>
        <div className="brand" id="albert"><img src={ Albert } alt="albert" /></div>
        <div className="brand" id="voss"><img src={ Voss } alt="voss" /></div>
        <div className="brand" id="pr_electronics"><img src={ PrElectronics } alt="pr_electronics" /></div>
        <div className="brand" id="kern"><img src={ Kern } alt="kern" /></div>
        <div className="brand" id="baldwin"><img src={ Baldwin } alt="baldwin" /></div>
        <div className="brand" id="camozzi"><img src={ Camozzi } alt="camozzi" /></div>
      </div>
    </div>
  )
}

export default Providers