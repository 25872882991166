import React from 'react'
import './bottom.scss'

import { Privacidad, Cookies } from '../../assets/pdf/index'

const Bottom = () => {
  return (
    <div className='bottom section'>
      <i className="far fa-copyright" aria-hidden="true"></i>
        <a href=".">NÚÑEZ CORUÑA. </a>
        <span>Todos los derechos reservados </span>
        <a href={ Privacidad } target="_blank">- Política de privacidad </a>
        <a href={ Cookies } target="_blank">- Política de cookies </a>
    </div>
  )
}

export default Bottom