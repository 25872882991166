import React from 'react'
import './avisoCokies.scss'

import { Cookies } from '../../assets/pdf/index'
import { Cookie } from '../../assets/'

function cookieF() {
    
    let d = new Date();

    d.setMinutes(2 + d.getMinutes());

    document.cookie = "myCookieName=nunezCoruna";

    document.getElementById('cookiePopup').classList.add("hide");
    document.getElementById('cookiePopup').classList.remove("show");
};

const checkCookie = () => {
    let input = document.cookie.split("=");

    if(input[0] == "myCookieName") {
        document.getElementById('cookiePopup').classList.add("hide")
        document.getElementById('cookiePopup').classList.remove("show")
    } else {
        document.getElementById('cookiePopup').classList.remove("hide")
        document.getElementById('cookiePopup').classList.add("show")
    }
}

window.onload = () => {
    setTimeout(() => {
        checkCookie();
    }, 100);
};

const AvisoCokies = () => {
  return (
    <div className='avisoCookies'>

        <div className="hide" id="cookiePopup">
            <img src={Cookie} />
            <p>
                Our website uses cookies to provide your
                browsing experience and
                relevant information. Before continuing to
                use our website, you agree &
                accept of our <a href={Cookies} target='_blank'> Cookie Policy &
                Privacy</a>
            </p>
            <button id='acceptCookies' onClick={cookieF}>Accept</button>
        </div>
    </div>
  )
}

export default AvisoCokies