import React from 'react'
import './footer.scss'

import { LogoVineta, LogoBottom } from '../../assets/index'

const Footer = () => {
  return (
    <div className='footer section'>
      <img src={ LogoVineta } alt="LogoVineta" className='logoViñeta' />
      <div className="nc_info">
        <div className="image">
          <img src={ LogoBottom } alt="LogoBottom" />
        </div>
        <div className="info"></div>
        <div className="find_us">
          <a href="https://www.google.com/maps/place/N%C3%BA%C3%B1ez+Coru%C3%B1a+~+Productos+Industriales/@43.3547935,-8.4246244,17z/data=!3m1!4b1!4m5!3m4!1s0xd2e7cedb8c6a6a1:0x4e5777cdda3328db!8m2!3d43.3547178!4d-8.4224722" target="_blank">
            <ion-icon name="location-sharp" />
            ¿Dónde estamos?
          </a>
        </div>
      </div>
      <div className="summary">
        <ul className="footerNews">
          <li>
            <h3 className="widget_title">
              NOTICIAS
            </h3>
          </li>
        </ul>
        <ul className="footerContact">
          <li>
            <h3 className="widget_title">
              CONTACTO
            </h3>
          </li>
          <li className="widget widget_location">
            <ion-icon name="location-sharp" />
            <p>
              Rúa Gutemberg, 32, 15008 LA GRELA, A Coruña
            </p>
          </li>
          <li className="widget widget_phone">
            <ion-icon name="call-sharp" />
            <p>
              981 14 01 94
            </p>
          </li>
          <li className="widget widget_mail">
            <ion-icon name="mail-sharp" />
            <a href="mailto:info@nunezcoruna.com">
              info@nunezcoruna.com
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Footer