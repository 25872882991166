import React from 'react';
import './App.css';

import { Navbar, Hero, Home, Catalogue, Sectors, Providers, Contact, Footer, Bottom } from './containers';
import { MobileMenu } from './components';
import AvisoCokies from './components/avisoCookies/AvisoCokies';

const App = () => {
  return (
    <div className='app'>

        <Navbar />
        <MobileMenu />
        <div id='mainPage'>
            <Hero />
            <div className='separator' />
            <Home />
            <div className='separator' />
            <Catalogue />
            <div className='separator' />
            <Sectors />
            <div className='separator' />
            <Providers />
        </div>
        <div id='contactWrapper'>
            <Contact id='contact' />
            <div className='separator' />
        </div>
        <AvisoCokies />
        <Footer />
        <Bottom />

    </div>
  )
}

export default App