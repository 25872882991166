import React from 'react'
import './navbar.scss'

function contact() {
  document.getElementById('contactWrapper').style.display = "block";
  document.getElementById('mainPage').style.display = "none";
}

function noContact () {
  document.getElementById('contactWrapper').style.display = "none";
  document.getElementById('mainPage').style.display = "block";
}

const Navbar = () => {
  return (
    <div className='navbar' id='navbar'>
      <div className='icon'>
        <a href='.'><span>núñez.</span></a>
      </div>
      <div className='menu'>
          <li className='menuComponent'>
            <a onClick={noContact} href="#home" className='menuItem fillText'>¿quiénes somos?</a>
          </li>
          <li className='menuComponent'>
            <a onClick={noContact} href="#catalogue" className='menuItem fillText'>catálogo</a>
          </li>
          <li className='menuComponent'>
            <a onClick={noContact} href="#sectors" className='menuItem fillText'>sectores</a>
          </li>
          <li className='menuComponent'>
            <a onClick={noContact} href='#providers' className='menuItem fillText'>proveedores</a>
          </li>
          <li className='menuComponent'>
            <a onClick={contact} className='menuItem fillText fillBox'>contáctanos</a>
          </li>
      </div>




    </div>
  )
}

export default Navbar