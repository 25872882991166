import React from 'react'
import './catalogue.scss'

import '../../components/titleCard/titleCard.scss'
import { Neumatica, Hidraulica, Valvulas, Transmision, Instrumentacion } from '../../assets/pdf'

const Catalogue = () => {
  return (
    <div className='catalogue section' id='catalogue'>
        <div className='titleCard'>
            <span id="titleCard_text">catálogo</span>
        </div>
        <div className="bannerBox">
            <div className="banner">
                <ul className="banner_list">
                    <li>
                        <a data-text="NEUMÁTICA" href={ Neumatica } target="_blank" className="animated_text">
                            NEUMÁTICA
                        </a>
                    </li>
                    <li>
                        <a data-text="HIDRÁULICA" href={ Hidraulica } target="_blank" className="animated_text">
                            HIDRÁULICA
                        </a>
                    </li>
                    <li>
                        <a data-text="VALVULERÍA" href={ Valvulas } target="_blank" className="animated_text">
                            VALVULERÍA
                        </a>
                    </li>
                </ul>
                <ul className="banner_list">
                    <li>
                        <a data-text="TRANSMISIÓN" href={ Transmision } target="_blank" className="animated_text">
                            TRANSMISIÓN
                        </a>
                    </li>
                    <li>
                        <a data-text="INSTRUMENTACIÓN" href={ Instrumentacion } target="_blank" className="animated_text">
                            INSTRUMENTACIÓN
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
  )
}

export default Catalogue