import React from 'react'
import './services.scss'

import { RiTruckLine, Ri24HoursLine, RiParkingBoxLine } from 'react-icons/ri'

const Services = () => {
  return (
    <div className="cards-list">
      <div className="card 1">
        <div className="card_image"> 
          <RiTruckLine className='icon' />
        </div>
        <span>REPARTO</span>
      </div>
      
      <div className="card 2">
        <div className="card_image">
          <Ri24HoursLine className='icon' />
        </div>
        <span>SERVICIO URGENTE</span>
      </div>
      
      <div className="card 3">
        <div className="card_image">
          <RiParkingBoxLine className='icon' />
        </div>
        <span>PARKING PARA CLIENTES</span>
      </div>
    </div>
  )
}

export default Services