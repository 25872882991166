import React from 'react'
import './sectors.scss'

const Sectors = () => {
  return (
    <div className='sectors section' id='sectors'>
      <div className='titleCard'>
        <span id="titleCard_text">sectores</span>
      </div>

      <div className="work_sectors">
        <div className="sector industry">
            <i className="sector_icon fa fa-industry"></i>
            <span>INDUSTRIA</span>
        </div>
        <div className="sector automotive">
            <i className="sector_icon fa fa-car"></i>
            <span>AUTOMOCIÓN</span>
        </div>
        <div className="sector feeding">
            <i className="sector_icon fa fa-burger"></i>
            <span>ALIMENTACIÓN</span>
        </div>
        <div className="sector energy">
            <i className="sector_icon fa fa-battery"></i>
            <span>ENERGÍA</span>
        </div>
        <div className="sector construction">
            <i className="sector_icon fa fa-house"></i>
            <span>CONSTRUCCIÓN</span>
        </div>
        <div className="sector naval">
            <i className="sector_icon fa fa-ship"></i>
            <span>NAVAL</span>
        </div>
        <div className="sector other">
            <i className="sector_icon fa fa-plus"></i>
            <span>OTROS SECTORES</span>
        </div>
    </div>
    </div>
  )
}

export default Sectors