import React from 'react'
import './mobileMenu.scss'

import { GiHamburgerMenu } from 'react-icons/gi'

function showMenu () {
	document.querySelector('.menu__body').style.bottom = "0";
};

function hideMenu () {
	document.querySelector('.menu__body').style.bottom = "-100rem";
};

function contact() {
  document.getElementById('contactWrapper').style.display = "block";
  document.getElementById('mainPage').style.display = "none";
}

function noContact () {
  document.getElementById('contactWrapper').style.display = "none";
  document.getElementById('mainPage').style.display = "block";
}

const MobileMenu = () => {
  return (
    <div className='mobileMenu'>
        <button className="menu__button" onClick={showMenu}>
            <GiHamburgerMenu className='icon' />
        </button>
        <section className="menu__body">
            <div className="menu__header" onClick={hideMenu}>
                <label>
                </label>
                <p>Navegación Rápida</p>
                <button title="Close">
                    <div></div>
                </button>
            </div>
            <div className="menu__links">
                <a onClick={noContact} href="#home">EMPRESA</a>
                <a onClick={noContact} href="#catalogue">CATÁLOGO</a>
                <a onClick={noContact} href="#sectors">SECTORES</a>
                <a onClick={contact}>CONTACTO</a>
            </div>
            <div className="menu__contact">
                <a href="tel:981 14 01 94"> 
                <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" height="24"  viewBox="0 0 24 24"  width="24">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z" />
                </svg>
                <span>Llamar</span>
                </a>
                <a href='mailto:info@nunezcoruna.com'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" height="24" viewBox="0 0 24 24" width="24">
                        <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
                        <path d="M0 0h24v24H0z" fill="none" />
                    </svg>
                    <span>Correo</span>
                </a>
                <a href="https://www.google.com/maps/place/N%C3%BA%C3%B1ez+Coru%C3%B1a+~+Productos+Industriales/@43.3547935,-8.4246244,17z/data=!3m1!4b1!4m5!3m4!1s0xd2e7cedb8c6a6a1:0x4e5777cdda3328db!8m2!3d43.3547178!4d-8.4224722" target="_blank"> 
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" height="24" viewBox="0 0 24 24" width="24">
                        <path d="M21.71 11.29l-9-9c-.39-.39-1.02-.39-1.41 0l-9 9c-.39.39-.39 1.02 0 1.41l9 9c.39.39 1.02.39 1.41 0l9-9c.39-.38.39-1.01 0-1.41zM14 14.5V12h-4v3H8v-4c0-.55.45-1 1-1h5V7.5l3.5 3.5-3.5 3.5z" />
                        <path d="M0 0h24v24H0z" fill="none" />
                    </svg>
                    <span>dirección</span>
                </a>
            </div>
        </section>
        <div className="menu__overlay" onClick={hideMenu}></div>
    </div>
  )
}

export default MobileMenu