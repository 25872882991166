import React from 'react'
import './hero.scss'

import { Logo } from '../../assets'

const Hero = () => {
  return (
    <div className='hero section'>
      <img src={ Logo } alt="" />
    </div>
  )
}

export default Hero